function camelizeString(snakeString = ''): CamelCase<string> {
  return snakeString
    .trim()
    .replace(/(-|_|\s)+(.)?/g, (_match, _sep, c) => (c ? c.toUpperCase() : ''));
}

function camelizeUntyped(snakeData: any = {}, recursive = false, blocklist: string[] = []): any {
  const camelData = Array.isArray(snakeData) ? [] : {};
  Object.keys(snakeData).forEach((key) => {
    const camelKey = blocklist.includes(key) ? key : camelizeString(key);
    const data = snakeData[key];
    if (recursive && data && typeof data === 'object') {
      camelData[camelKey] = camelizeUntyped(data, true, blocklist);
    } else {
      camelData[camelKey] = data;
    }
  });
  return camelData;
}

type Primitive = Date | string | number | boolean | bigint | symbol | undefined | null;

export type CamelCase<S extends string> = S extends `${infer P1}_${infer P2}${infer P3}`
  ? `${Lowercase<P1>}${Uppercase<P2>}${CamelCase<P3>}`
  : S;

export type NonRecursiveCamelize<T> = {
  [K in keyof T as CamelCase<string & K>]: T[K];
};

export type Camelize<T> = T extends Array<infer U>
  ? Array<Camelize<U>>
  : T extends object
  ? { [K in keyof T as CamelCase<string & K>]: Camelize<T[K]> }
  : T extends Primitive
  ? T
  : never;

function camelize<T>(
  camelData: T,
  recursive?: false,
  blocklist?: string[]
): NonRecursiveCamelize<T>;
function camelize<T>(camelData: T, recursive: true, blocklist?: string[]): Camelize<T>;
function camelize<T>(snakeData: T, recursive = false, blocklist: string[] = []): Camelize<T> {
  return camelizeUntyped(snakeData, recursive, blocklist) as Camelize<T>;
}

function snakeifyString(camelString = ''): SnakeCase<string> {
  return camelString
    .trim()
    .replace(/([a-z\d])([A-Z]+)/g, '$1_$2')
    .replace(/([A-Z\d]+)([A-Z][a-z])/g, '$1_$2')
    .replace(/[-\s]+/g, '_')
    .toLowerCase();
}

function snakeifyUntyped(camelData: any = {}, recursive?: boolean): any {
  const snakeData = Array.isArray(camelData) ? [] : {};
  Object.keys(camelData).forEach((key) => {
    const snakeKey = snakeifyString(key);
    const data = camelData[key];
    if (recursive && data && typeof data === 'object') {
      snakeData[snakeKey] = snakeifyUntyped(data, true);
    } else {
      snakeData[snakeKey] = data;
    }
  });
  return snakeData;
}

export type SnakeCase<S extends string> = S extends `${infer T}${infer U}`
  ? `${T extends Capitalize<T> ? '_' : ''}${Lowercase<T>}${SnakeCase<U>}`
  : S;

export type NonRecursiveSnakeify<T> = {
  [K in keyof T as SnakeCase<string & K>]: T[K];
};

export type Snakeify<T> = T extends Array<infer U>
  ? Array<Snakeify<U>>
  : T extends object
  ? { [K in keyof T as SnakeCase<string & K>]: Snakeify<T[K]> }
  : T extends Primitive
  ? T
  : never;

function snakeify<T>(camelData: T, recursive?: false): NonRecursiveSnakeify<T>;
function snakeify<T>(camelData: T, recursive: true): Snakeify<T>;
function snakeify<T>(camelData: T, recursive = false): Snakeify<T> {
  return snakeifyUntyped(camelData, recursive) as Snakeify<T>;
}

function kebabifyString(camelString = ''): string {
  return camelString
    .trim()
    .replace(/([a-z\d])([A-Z]+)/g, '$1-$2')
    .replace(/([A-Z\d]+)([A-Z][a-z])/g, '$1-$2')
    .replace(/[_\s]+/g, '-')
    .toLowerCase();
}

export {
  camelize,
  snakeify,
  camelizeUntyped,
  snakeifyUntyped,
  camelizeString,
  snakeifyString,
  kebabifyString,
};
