import React, { CSSProperties, forwardRef, ReactNode } from 'react';
import styled from 'styled-components';

import { MicrodataHTMLAttributes } from '@rover/react-lib/src/components/microdata';

import type { AlignmentType } from '../layout.constants';
import { Alignment } from '../layout.constants';

export type Props = {
  children: ReactNode;
  style?: CSSProperties;
  vAlign?: AlignmentType;
  hAlign?: AlignmentType;
  role?: string | undefined;
  ref?: React.Ref<HTMLDivElement>;
  onMouseEnter?: any;
  onMouseLeave?: any;
  onFocus?: any;
  onBlur?: any;
} & MicrodataHTMLAttributes;

const DivWithRef = forwardRef<HTMLDivElement, Props>(function DivWithRef(
  { vAlign, hAlign, ...props }: Props, // eslint-disable-line  @typescript-eslint/no-unused-vars
  ref
) {
  return (
    <div {...props} ref={ref}>
      {props.children}
    </div>
  );
});

const VerticalLayout = styled(DivWithRef)`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: ${(props) => props.vAlign?.toString()};
  align-items: ${(props) => props.hAlign?.toString()};
`;

VerticalLayout.defaultProps = {
  style: {},
  vAlign: Alignment.START,
  hAlign: Alignment.START,
};

export default VerticalLayout;
