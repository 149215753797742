import Enum from '@rover/types/src/Enum';

export default class ZIndex extends Enum<number> {
  static ZERO = new ZIndex(0);

  static ONE = new ZIndex(1);

  static FLAG_ICON = new ZIndex(6);

  static NAVBAR = new ZIndex(1000);

  static NAVBAR_FIXED = new ZIndex(1030);

  static MODAL_INNER_INTL_PHONE = new ZIndex(1059);

  static POPOVER = new ZIndex(1060);

  static TOOLTIP = new ZIndex(1070);

  static DROPDOWN = new ZIndex(1075);

  static DIALOG = new ZIndex(1077);

  static MODAL_BACKGROUND = new ZIndex(1079);

  static MODAL = new ZIndex(1080);

  static MODAL_OVERLAY = new ZIndex(1200);

  static isLocked = true;

  toNumber(): number {
    return this.value;
  }
}
