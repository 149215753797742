import flatMap from 'lodash-es/flatMap';

import Enum from '@rover/types/src/Enum';

export const XXS_MAX = 374; // mostly iPhone 5

export const MOBILE_MAX = 450;

export const XS_MAX = 767;
export const SM_MIN = 768;
export const SM_MAX = 991;
export const MD_MIN = 992;
export const MD_MAX = 1199;
export const LG_MIN = 1200;
// the tablet breakpoints were created for the search page and overlap with the other breakpoints. Do not use without permission from design
export const TABLET_MIN = SM_MIN;
export const TABLET_MAX = 1024;

export const PREFIX = '@media ';
export const MQ_OR_DELIMETER = /[\n,]+/;
export default class MediaQuery extends Enum<string> {
  // no space after `min-width in `(min-width:768px)` due to a bug in jest-styled-component
  // https://github.com/styled-components/jest-styled-components/issues/98
  static XXS = new MediaQuery(`${PREFIX}(max-width:${XXS_MAX}px)`);

  static MOBILE = new MediaQuery(`${PREFIX}(max-width:${MOBILE_MAX}px)`);

  static MOBILE_UP = new MediaQuery(`${PREFIX}(min-width:${MOBILE_MAX}px)`);

  static XS = new MediaQuery(`${PREFIX}(max-width:${XS_MAX}px)`);

  static XS_DOWN = new MediaQuery(`${PREFIX}(max-width:${XS_MAX}px)`);

  static NOT_XS = new MediaQuery(`${PREFIX}(min-width:${SM_MIN}px)`);

  static SM = new MediaQuery(`${PREFIX}(min-width:${SM_MIN}px) and (max-width:${SM_MAX}px)`);

  static SM_UP = new MediaQuery(`${PREFIX}(min-width:${SM_MIN}px)`);

  static SM_DOWN = new MediaQuery(`${PREFIX}(max-width:${SM_MAX}px)`);

  static NOT_SM = new MediaQuery(`${PREFIX}
    (max-width:${XS_MAX}px),
    (min-width:${MD_MIN}px)`);

  /**
   * The tablet breakpoints were created for the search page and overlap with the other breakpoints.
   * Do not use without permission from design
   */
  static TABLET_DOWN = new MediaQuery(`${PREFIX}(max-width:${TABLET_MAX}px)`);

  static MD = new MediaQuery(`${PREFIX}(min-width:${MD_MIN}px) and (max-width:${MD_MAX}px)`);

  static MD_UP = new MediaQuery(`${PREFIX}(min-width:${MD_MIN}px)`);

  static MD_DOWN = new MediaQuery(`${PREFIX}(max-width:${MD_MAX}px)`);

  static NOT_MD = new MediaQuery(`${PREFIX}
    (max-width:${SM_MAX}px),
    (min-width:${LG_MIN}px)`);

  static LG = new MediaQuery(`${PREFIX}(min-width:${LG_MIN}px)`);

  static NOT_LG = new MediaQuery(`${PREFIX}(max-width:${MD_MAX}px)`);

  //  ${MQ.MD_UP} and ${MQ.HD} will not work. Use ${MQ.getHDMediaQuery(MQ.MD_UP)} instead
  static HD = new MediaQuery(`${PREFIX}
  only screen and (-webkit-min-device-pixel-ratio:2),
  only screen and (min--moz-device-pixel-ratio:2),
  only screen and (-o-min-device-pixel-ratio:2/1),
  only screen and (min-device-pixel-ratio:2),
  only screen and (min-resolution:192dpi),
  only screen and (min-resolution:2dppx)`);

  static isLocked = true;

  getBreakpointString(): string {
    return this.value.substring(PREFIX.length).trim();
  }

  static getHDMediaQuery(mq: MediaQuery): string {
    const hdStrings: string[] = MediaQuery.HD.getBreakpointString().split(MQ_OR_DELIMETER);
    const mqStrings: string[] = mq.getBreakpointString().split(MQ_OR_DELIMETER);
    // return a cartesian product of two array joins by 'and'
    // https://stackoverflow.com/a/16114118
    const hdMediaQueryString = flatMap(hdStrings, (hdString: string): string[] =>
      mqStrings.map((mqString) => `${hdString} and ${mqString}`)
    ).join(',');
    return `${PREFIX} ${hdMediaQueryString}`;
  }
}
export const matchesMediaQuery = (mediaQuery: MediaQuery): boolean =>
  window.matchMedia && window.matchMedia(mediaQuery.getBreakpointString()).matches;
export const getMediaSelector = (mediaQuery: MediaQuery): string =>
  mediaQuery.getBreakpointString();
