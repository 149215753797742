import isDate from 'lodash-es/isDate';
import isObject from 'lodash-es/isObject';
import isString from 'lodash-es/isString';
import isUndefined from 'lodash-es/isUndefined';
import moment from 'moment';
import uuid from 'uuid';

import { I18N_FORMAT_MAP } from '@rover/kibble/styles';
import { DEFAULT_LANG } from '@rover/shared/js/constants/i18n/language';

import type { Time } from '@rover/types/src/datetime/Time';

export function getTimeFormat(language?: string | null): string {
  return I18N_FORMAT_MAP[language || DEFAULT_LANG].TIME_SIMPLE;
}

export default function timeFactory(
  value?: string | Date | Partial<Time> | null | undefined,
  language?: string | null
): Time {
  if (isUndefined(value)) {
    return {
      id: uuid.v4(),
      value: '',
    };
  }

  if (isString(value) || isDate(value)) {
    let time = '';

    if (value instanceof Date) {
      time = moment(value).format(getTimeFormat(language));
    } else if (typeof value === 'string') {
      time = value;
    }

    return {
      id: uuid.v4(),
      value: time,
    };
  }

  // the typeof is to make tyepescript happy
  if (isObject(value) && typeof value === 'object') {
    return {
      id: uuid.v4(),
      value: '',
      ...value,
    };
  }

  throw new Error('timeFactory called with incorrect arg type');
}
