import { MessageDescriptor } from '@lingui/core';
import { plural, t } from '@lingui/macro';

export enum ServiceType {
  OVERNIGHT_BOARDING = 'overnight-boarding',
  OVERNIGHT_TRAVELING = 'overnight-traveling',
  DOG_WALKING = 'dog-walking',
  DROP_IN = 'drop-in',
  DOGGY_DAY_CARE = 'doggy-day-care',
  ON_DEMAND_DOG_WALKING = 'on-demand-dog-walking',
  ON_DEMAND_GROOMING = 'on-demand-grooming',
  ON_DEMAND_DROP_IN = 'on-demand-drop-in',
  DOG_TRAINING = 'dog-training',
}

export const SERVICE_TYPE_CHOICES: Record<string, ServiceType> = {
  boarding: ServiceType.OVERNIGHT_BOARDING,
  traveling: ServiceType.OVERNIGHT_TRAVELING,
  dropIn: ServiceType.DROP_IN,
  dayCare: ServiceType.DOGGY_DAY_CARE,
  walking: ServiceType.DOG_WALKING,
  training: ServiceType.DOG_TRAINING,
  onDemandWalking: ServiceType.ON_DEMAND_DOG_WALKING,
  assignmentGrooming: ServiceType.ON_DEMAND_GROOMING,
  assignmentDropIn: ServiceType.ON_DEMAND_DROP_IN,
};

// model after SERVICE_TYPE_PRICE_UNITS or ALTERNATIVE_RATE_UNIT
export const ALTERNATIVE_RATE_UNITS = {
  night: (count: number): MessageDescriptor =>
    plural({
      value: count,
      one: 'night',
      other: 'nights',
    }),
  day: (count: number): MessageDescriptor =>
    plural({
      value: count,
      one: 'day',
      other: 'days',
    }),
  visit: (count: number): MessageDescriptor =>
    plural({
      value: count,
      one: 'visit',
      other: 'visits',
    }),
  walk: (count: number): MessageDescriptor =>
    plural({
      value: count,
      one: 'walk',
      other: 'walks',
    }),
  groom: (count: number): MessageDescriptor =>
    plural({
      value: count,
      one: 'groom',
      other: 'grooms',
    }),
};
// model after UNIT_CHOICES which is used in AddOnType.unit
export const ADD_ON_TYPE_UNITS = {
  night: (count: number): MessageDescriptor =>
    plural({
      value: count,
      one: 'night',
      other: 'nights',
    }),
  bath: (count: number): MessageDescriptor =>
    plural({
      value: count,
      one: 'groom',
      other: 'grooms',
    }),
  walk: (count: number): MessageDescriptor =>
    plural({
      value: count,
      one: 'walk',
      other: 'walks',
    }),
  round_trip: (count: number): MessageDescriptor =>
    plural({
      value: count,
      one: 'round trip',
      other: 'round trips',
    }),
  session: (count: number): MessageDescriptor =>
    plural({
      value: count,
      one: 'session',
      other: 'sessions',
    }),
};
// all possible unit values returned by service.add_on.unit. See services.models.ServiceAddOnMixin#get_rate_unit in backend
export const SERVICE_ADD_ON_UNITS = { ...ALTERNATIVE_RATE_UNITS, ...ADD_ON_TYPE_UNITS };
// SERVICE_TYPE_ALTERNATIVE_RATE_UNIT, model after SERVICE_TYPE_PRICE_UNITS
export const SERVICE_TYPE_PRICE_UNITS = {
  [SERVICE_TYPE_CHOICES.boarding]: ALTERNATIVE_RATE_UNITS.night,
  [SERVICE_TYPE_CHOICES.traveling]: ALTERNATIVE_RATE_UNITS.night,
  [SERVICE_TYPE_CHOICES.dayCare]: ALTERNATIVE_RATE_UNITS.day,
  [SERVICE_TYPE_CHOICES.dropIn]: ALTERNATIVE_RATE_UNITS.visit,
  [SERVICE_TYPE_CHOICES.walking]: ALTERNATIVE_RATE_UNITS.walk,
  [SERVICE_TYPE_CHOICES.onDemandWalking]: ALTERNATIVE_RATE_UNITS.walk,
  [SERVICE_TYPE_CHOICES.assignmentGrooming]: ALTERNATIVE_RATE_UNITS.groom,
  [SERVICE_TYPE_CHOICES.assignmentDropIn]: ALTERNATIVE_RATE_UNITS.visit,
};
// model after src/aplaceforrover/services/models.py:121 FREQUENCY_CHOICES + PRICE_UNITS
export const ADD_ON_FREQUENCY = {
  ...ALTERNATIVE_RATE_UNITS,
  night: (count: number): MessageDescriptor =>
    plural({
      value: count,
      one: 'night',
      other: 'nights',
    }),
  event: (count: number): MessageDescriptor =>
    plural({
      value: count,
      one: 'time',
      other: 'times',
    }),
  one_time_event: () => t`time`,
};

export const REPEAT_SERVICE_TYPE_LABELS = {
  [SERVICE_TYPE_CHOICES.dropIn]: t`Which days would you like a drop-in?`,
  [SERVICE_TYPE_CHOICES.dayCare]: t`Which days would you like day care?`,
  [SERVICE_TYPE_CHOICES.walking]: t`Which days would you like a walk?`,
  [SERVICE_TYPE_CHOICES.assignmentGrooming]: t`Which days would you like grooming?`,
};

export const SERVICE_TYPE_DESCRIPTION = {
  [ServiceType.OVERNIGHT_BOARDING]: t`Your pets stay overnight in your sitter’s home.`,
  [ServiceType.OVERNIGHT_TRAVELING]: t`Your sitter takes care of your pets and your house.`,
  [ServiceType.DOGGY_DAY_CARE]: t`Your dog spends the day with a trusted local day care provider.`,
  [ServiceType.DROP_IN]: t`Your sitter drops by your home to play, feed, and give your pet potty breaks or clean the litter box.`,
  [ServiceType.DOG_WALKING]: t`Your dog gets a walk around your neighborhood.`,
};
