import moment from 'moment';

import 'moment/min/locales';

import { capitalizeFirstLetter } from '../capitalizeFirstLetter';

const DAY_OF_WEEK_SUNDAY = 0;
// These are intentionally untranslated, they are used
// to map a day number to the English day of week,
// which is the expected format of the DayWithTimeRangesReadSerializer
const LOWER_US_DAYS_OF_WEEK = [
  'sunday',
  'monday',
  'tuesday',
  'wednesday',
  'thursday',
  'friday',
  'saturday',
];
const LOWER_US_DAYS_OF_WEEK_IDS = {
  sunday: 0,
  monday: 1,
  tuesday: 2,
  wednesday: 3,
  thursday: 4,
  friday: 5,
  saturday: 6,
};
export default class Week {
  // e.g. 'S'
  static upperCharName(dayNumber: number): string {
    return moment().weekday(dayNumber).format('dd').charAt(0);
  }

  // e.g. 'Sun'
  static upperShortName(dayNumber: number): string {
    return moment().weekday(dayNumber).format('ddd');
  }

  // e.g. 'Sunday'
  static upperLongName(dayNumber: number): string {
    const longName = moment().weekday(dayNumber).format('dddd');

    // We need to ensure the first letter is capitalized due to how moment
    // handles localized day names.
    // English: `longName` => 'Monday'
    // French: `longName` => 'lundi'
    return capitalizeFirstLetter(longName);
  }

  // e.g. 'Sundays'
  // eslint-disable-next-line no-unused-vars
  static upperPluralLongName(): string {
    throw new Error('Not implemented: contextless upperPluralLongName');
  }

  // e.g. 'sunday'
  static lowerLongName(dayNumber: number): string {
    return this.upperLongName(dayNumber).toLowerCase();
  }

  // e.g. ['sunday', 'monday', etc.]
  static days(): string[] {
    return [DAY_OF_WEEK_SUNDAY, 1, 2, 3, 4, 5, 6].map((n) => this.lowerLongName(n));
  }

  // e.g. dayNumber('sunday') => 0
  static dayNumber(dayName: string, localized = false): number {
    const days = localized
      ? this.days()
      : this.getDaysOrderByFirstDayOfWeek(
          moment
            .localeData('en')
            .weekdays()
            .map((d) => d.toLowerCase())
        );

    for (let i = 0; i < 7; i += 1) {
      if (dayName && days[i] && days[i].startsWith(dayName.toLowerCase())) return i;
    }

    return -1;
  }

  static dayNumberUS(dayName: string): number {
    return dayName in LOWER_US_DAYS_OF_WEEK_IDS ? LOWER_US_DAYS_OF_WEEK_IDS[dayName] : -1;
  }

  // e.g 0 or 1
  static firstDayOfWeek(): number {
    return moment.localeData().firstDayOfWeek();
  }

  static lowerEnglishName(dayNumber: number): string {
    return [
      ...LOWER_US_DAYS_OF_WEEK.slice(this.firstDayOfWeek()),
      ...LOWER_US_DAYS_OF_WEEK.slice(0, this.firstDayOfWeek()),
    ][dayNumber];
  }

  // as opposed to lowerEnglishName above, sunday is the first day
  static lowerEnglishNameUSLocale(dayNumber: number): string {
    return LOWER_US_DAYS_OF_WEEK[dayNumber];
  }

  // e.g. getDaysOrderByFirstDayOfWeek() => [1, 2, 3, 4, 5, 6, 0] if FIRST_DAY_OF_WEEK === 1
  static getDaysOrderByFirstDayOfWeek(defaultDaysOrder: any[] = [0, 1, 2, 3, 4, 5, 6]): any[] {
    return [
      ...defaultDaysOrder.slice(this.firstDayOfWeek()),
      ...defaultDaysOrder.slice(0, this.firstDayOfWeek()),
    ];
  }
}
