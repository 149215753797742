import React from 'react';
import classNames from 'classnames';

import '@rover/shared/css/rover_icons/rover_icons.css';

export type Props = {
  icon?: string;
  className?: string;
  style?: React.CSSProperties;
  onClick?: () => void;
};

const Icon = ({ icon, className = '', ...other }: Props): JSX.Element => {
  const props = {
    ...other,
    className: classNames(
      {
        'rover-icon': typeof icon === 'string' && icon.includes('rover'),
        fa: typeof icon === 'string' && icon.indexOf('fa') !== -1,
      },
      icon,
      className
    ),
  };
  return <i {...props} />;
};

export default Icon;
