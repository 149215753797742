import React, { CSSProperties, ReactNode } from 'react';
import styled from 'styled-components';

import { DSTokenMap } from '@rover/kibble/styles';

import type { AlignmentType } from '../layout.constants';
import { Alignment } from '../layout.constants';

export type Props = {
  as?: React.ElementType;
  children: ReactNode;
  style?: CSSProperties;
  vAlign?: AlignmentType;
  hAlign?: AlignmentType;
  noWrap?: boolean;
  role?: string;
  id?: string;
};

// eslint-disable-next-line rover/prefer-kibble-components
export const HorizontalWrapper = styled.div<{
  $hAlign: AlignmentType;
  $vAlign: AlignmentType;
  $noWrap: boolean;
}>`
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: ${(props): string => props.$hAlign.toString()};
  align-items: ${(props): string => props.$vAlign.toString()};
  flex-wrap: ${(props): string => (props.$noWrap ? 'nowrap' : 'wrap')};
  flex-shrink: 0;
  margin-bottom: 0;
`;

const HorizontalLayout = ({
  vAlign = Alignment.START,
  hAlign = Alignment.START,
  noWrap = false,
  ...other
}: Props): JSX.Element => (
  <HorizontalWrapper {...other} $vAlign={vAlign} $hAlign={hAlign} $noWrap={noWrap} />
);

export default HorizontalLayout;
