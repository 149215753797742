import React, { CSSProperties, ReactNode } from 'react';
import styled from 'styled-components';

import { MQ } from '@rover/kibble/styles';

import type { AlignmentType } from '../layout.constants';
import { Alignment } from '../layout.constants';

export type Props = {
  children?: ReactNode;
  hAlign?: AlignmentType;
  hAlignBreakPoint?: AlignmentType;
  role?: string | undefined;
  style?: CSSProperties;
  vAlign?: AlignmentType;
  vAlignBreakPoint?: AlignmentType;
  vBreakPoint?: MQ;
  vBreakPointFlexDirection?: 'row' | 'column';
};

const ResponsiveWrapper = styled.div<{
  $hAlign: AlignmentType;
  $hAlignBreakPoint?: AlignmentType;
  $vAlign: AlignmentType;
  $vAlignBreakPoint?: AlignmentType;
  $vBreakPoint: MQ;
  $vBreakPointFlexDirection: 'row' | 'column';
}>`
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: ${({ $vAlign }) => $vAlign.toString()};
  justify-content: ${({ $hAlign }) => $hAlign.toString()};

  ${({ $vBreakPoint }) => $vBreakPoint.toString()} {
    flex-direction: ${({ $vBreakPointFlexDirection }) => $vBreakPointFlexDirection};
    justify-content: ${({ $vAlignBreakPoint, $vAlign }) => {
      if ($vAlignBreakPoint) {
        return $vAlignBreakPoint.toString();
      }
      return $vAlign.toString();
    }};
    align-items: ${({ $hAlignBreakPoint, $hAlign }) => {
      if ($hAlignBreakPoint) {
        return $hAlignBreakPoint.toString();
      }
      return $hAlign.toString();
    }};
  }
`;

const ResponsiveLayout = ({
  hAlign = Alignment.START,
  hAlignBreakPoint,
  vAlign = Alignment.START,
  vAlignBreakPoint,
  vBreakPoint = MQ.XS,
  vBreakPointFlexDirection = 'column',
  ...other
}: Props): JSX.Element => (
  <ResponsiveWrapper
    {...other}
    $hAlign={hAlign}
    $hAlignBreakPoint={hAlignBreakPoint}
    $vAlign={vAlign}
    $vAlignBreakPoint={vAlignBreakPoint}
    $vBreakPoint={vBreakPoint}
    $vBreakPointFlexDirection={vBreakPointFlexDirection}
  />
);

export default ResponsiveLayout;
