// eslint-disable-next-line no-shadow
export enum Alignment {
  BASELINE = 'baseline',
  START = 'flex-start',
  CENTER = 'center',
  END = 'flex-end',
  STRETCH = 'stretch',
  SPACE_BETWEEN = 'space-between',
  SPACE_AROUND = 'space-around',
}

export type AlignmentType = `${Alignment}`;
