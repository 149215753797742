import Enum from '@rover/types/src/Enum';

export default class Spacing extends Enum<number> {
  static XS = new Spacing(4);

  static S = new Spacing(8);

  static M = new Spacing(16);

  static L = new Spacing(24);

  static XL = new Spacing(48);

  static XXL = new Spacing(64);

  static XXXL = new Spacing(96);

  static isLocked = true;

  toString(): string {
    return `${this.value}px`;
  }

  toNumber(): number {
    return this.value;
  }
}
