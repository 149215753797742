import type { ReactNode } from 'react';
import React from 'react';
import styled from 'styled-components';

import { Box, BoxProps } from '@rover/kibble/core';

const SectionWrapper = styled(Box)`
  width: 100%;

  &:last-of-type {
    margin-bottom: 0;
  }
`;

export type Props = {
  children: ReactNode;
};

const SectionLayout = ({ marginBottom = '12x', children, ...other }: BoxProps): JSX.Element => (
  <SectionWrapper {...other} marginBottom={marginBottom}>
    {children}
  </SectionWrapper>
);

export default SectionLayout;
