interface DataLayerEvent {
  event: string;
  [index: string]: any;
}

declare global {
  interface Window {
    dataLayer: {
      push: (event: DataLayerEvent) => void;
      pop: () => any;
      length: number;
    };
  }
}
// Necessary for SSR
const windowExists = typeof window !== 'undefined';

if (windowExists) {
  window.dataLayer = window.dataLayer || [];
}

export const pushEventWithDebug = (event: DataLayerEvent): void => {
  // eslint-disable-next-line no-console
  console.debug('dataLayer', event);
  window.dataLayer.push(event);
};
export default windowExists ? window.dataLayer : ([] as unknown as typeof window.dataLayer);
