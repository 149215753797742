import type { ReactNode } from 'react';
import React from 'react';
import styled from 'styled-components';

import { MQ, Spacing } from '@rover/kibble/styles';

const PageWrapper = styled.div`
  margin-right: auto;
  margin-left: auto;
  margin-top: 80px;
  margin-bottom: 160px;
  padding: 0 16px;

  ${MQ.LG.toString()} {
    width: 1170px;
  }

  ${MQ.MD.toString()} {
    width: 970px;
  }

  ${MQ.SM.toString()} {
    width: 750px;
  }

  ${MQ.XS.toString()} {
    width: 100%;
    padding: 0 ${Spacing.M.toString()};
  }
`;

export type Props = {
  children: ReactNode;
  style?: React.CSSProperties;
};

/**
 *
 * @deprecated
 * This component has been deprecated, please use Section or Flex/Box instead
 *
 * Section doesn't have the same default margins as PageLayout.
 * We cannot match those old margins using the SpacingSystem. Therefore,
 * if necessary to exactly match existing usage, use the sx prop.
 * Before:
 * `<PageLayout>`
 * After:
 * `<Section sx={{marginTop: "80px", marginBottom: "160px">`
 *
 * Section doesn't accept the style prop.
 * Convert style props or styled-component styles to design system props.
 * Before:
 * `<PageLayout style={{ marginTop: '0px', marginBottom: '0px', maxWidth="800px" }}>`
 * After:
 * `<Section my="0x" maxWidth="800px">`
 *
 * Section already includes a flex container.
 * Background colors can be applied without needing an extra wrapper.
 * Before:
 * `<Flex background="primary" justifyContent="center">`
 *     `<PageLayout style={{margin: "0px auto"}}>>`
 * After:
 * `<Section background="primary">`
 *
 * If existing usage of PageLayout overrides the standard
 * padding or maxWidth, consider using Flex/Box instead.
 *
 */
const PageLayout = ({ children, style = {}, ...other }: Props): JSX.Element => (
  <PageWrapper style={style} {...other}>
    {children}
  </PageWrapper>
);

export default PageLayout;
